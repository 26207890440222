import { i18n } from 'config'
import useSWRInfinite from 'swr/infinite'
import { TBrendsResponse, TGetBrandsRequestPayload } from './types'
import { ELocales } from 'enums'
import { ApiBrandService } from './api.service'

export const useGetBrandsInfinity = (params: TGetBrandsRequestPayload) => {
   const lang = i18n.language as ELocales

   const allParams: TGetBrandsRequestPayload = { lang, ...params }
   const getKey = (pageIndex: number, previousPageData: TBrendsResponse) => {
      if (previousPageData && !previousPageData?.data?.length) return null
      return ['getProducts', { ...allParams, page: pageIndex }]
   }
   const { data, size, setSize, mutate, isLoading } = useSWRInfinite(
      getKey,
      ([, par]) => {
         if (typeof par === 'object') return ApiBrandService.getAsyncBrands(par)
      },
      { revalidateOnFocus: false, keepPreviousData: true, revalidateFirstPage: false }
   )

   const brandsRes: TBrendsResponse[] =
      data?.filter((brand): brand is TBrendsResponse => Boolean(brand)) || []
   const totalCount = brandsRes?.[0]?.meta?.totalCount
   const brandsList = brandsRes?.length
      ? [...brandsRes?.flatMap((item) => item?.data)]
      : []
   const isEndList = totalCount <= brandsList.length
   const loadMoreClick = () => {
      setSize((prev) => prev + 1)
   }
   return {
      brandsRes,
      brandsList,
      size,
      setSize,
      mutate,
      isLoading,
      loadMoreClick,
      totalCount,
      isEndList
   }
}
