import { DropDownList } from 'components/DropDownList'
import { Pagination } from 'components/Pagination'
import { ELocales } from 'enums'
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { RESPONSE } from 'store/product/consts'
import { api } from '../../config'
import { useLocalization, useTypedSelector } from '../../hooks'
import {
   categoryActions,
   getMainCategorySelector,
   getMainProductSelector,
   productActions
} from '../../store'
import { TProduct } from '../../store/product/types'
import { Button } from '../Button'
import { DateInput } from '../DateInput'
import { InfinityScroll } from '../InfinityScroll'
import { TOption } from '../PaginateDropDownList/types'
import { Popup } from '../Popup'
import { RelativePreloader } from '../Preloader'
import { SearchBar } from '../SearchBar'
import { FlexContainer, H2 } from '../Styled'
import { Table, TColumnTable, TSort } from '../Table'
import { PER_PAGE_ITEMS } from './consts'
import { Image, PreloaderContainer, selectStyles, TableContainer } from './styled'
import { TSelectProductPopup } from './types'
import { utils } from './utils'
import Select from 'react-select'

const ProductPopup: FC<TSelectProductPopup> = ({
   closeClickHandler,
   addProductClickHandler,
   addProductClickHandlerRelatedProducts,
   activeItems,
   checkAll,
   discountPopup,
   backgroundColor
}) => {
   const { t } = useTranslation()
   const dispatch = useDispatch()

   const { products, response: productsResponse } =
      useTypedSelector(getMainProductSelector)
   const { categories } = useTypedSelector(getMainCategorySelector)

   const [productsList, setProductsList] = useState<TProduct[]>([])
   const [page, setPage] = useState<number>(discountPopup ? 0 : 1)
   const [loading, setLoading] = useState<boolean>(false)
   const [activeProductsList, setActiveProductsList] = useState<string[]>([])
   const [checkedAll, setCheckedAll] = useState<string[]>([])
   const [selectedItems, setSelectedItems] = useState<number[]>([])
   // const [removeButtonState, setRemoveButtonState] = useState<boolean>(false)

   const [locale] = useLocalization()

   const [searchForm, setSearchForm] = useState({
      query: '',
      date_start: '',
      date_end: ''
   })

   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })

   const tableSortBy = useMemo(() => {
      {
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
      }
   }, [sortParamsForm])

   const [filterForm, setFilterForm] = useState<
      { value: string; label: string } | undefined
   >()

   const Events = {
      onChangeHandler: (e: React.SyntheticEvent) => {
         setPage(0)
         setProductsList([])
         const input = e.target as HTMLInputElement
         setSearchForm({ ...searchForm, [input.name]: input.value })
      },
      categoryListChangeHandler: (value: TOption) => {
         setSearchForm((form) => ({ ...form, category: value }))
      },
      sortToggleHandler: (sortBy: string, order: TSort) => {
         setSortParamsForm({ sortBy, order })
      },
      checkboxClickHandler: (e: SyntheticEvent, p: any, t: any, index: number) => {
         setActiveProductsList((list) => {
            const isChecked = (e.target as HTMLInputElement).checked
            const arr = list
            let id: string = ''

            productsList.forEach((product, i) => {
               if (index == i) id = product._id
            })

            if (isChecked && id) return [...new Set([...arr].concat([id]))]

            return [...new Set(arr.filter((item) => item != id))]
         })
      },
      nextProducts: () => {
         Requests.getProducts(page)
         setPage(page + 1)
      },
      addProductClickHandler: () => {
         if (addProductClickHandler) {
            addProductClickHandler(checkedAll.length ? checkedAll : activeProductsList)
            return
         }

         if (addProductClickHandlerRelatedProducts) {
            addProductClickHandlerRelatedProducts(
               checkedAll.length
                  ? checkedAll
                  : (productsList.filter(
                       (item) => activeProductsList.includes(item._id) as boolean
                    ) as any[])
            )
            return
         }
      },
      paginateDropDownChangeHandler: (value: unknown) => {
         const newValue = value as { label: string; value: string } | null
         setFilterForm(newValue || undefined)
      },
      checkedAll: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         checkedItemsIndex: number[]
      ) => {
         // setRemoveButtonState(hasCheckboxesActiveState)
         setSelectedItems(checkedItemsIndex)
         const arr: string[] = []
         for (let index of checkedItemsIndex) {
            arr.push(productsList[index]._id)
         }
         setCheckedAll(arr)
      },
      onPageChangeHandler: ({ selected }: { selected: number }) => {
         setPage(selected)
      }
   }

   const Requests = {
      getCategories: () => {
         dispatch(categoryActions.getCategories({ limit: 1000 }))
      },
      getProducts: (p: number = page) => {
         dispatch(
            productActions.getProducts({
               limit: discountPopup ? 50 : PER_PAGE_ITEMS,
               page: page,
               lang: locale as ELocales,
               hidden: 'false',
               category: filterForm?.value ? [filterForm?.value] : undefined,
               ...sortParamsForm,
               ...searchForm
            })
         )
         dispatch(productActions.setProducts({ data: [], meta: {} }))
      }
   }

   const Utils = {
      clearProducts: () => {
         dispatch(productActions.setProducts({ data: [], meta: null }))
      }
   }

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('product.code'),
            accessor: 'id',
            width: 150,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('photo'),
            accessor: 'photo'
         },
         {
            Header: t('label'),
            accessor: 'label',
            width: 250
         },
         {
            Header: t('publish.date'),
            accessor: 'publish_date',
            width: 200
         },
         {
            Header: t('category'),
            accessor: 'category',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         },
         {
            Header: t('total'),
            accessor: 'total',
            width: 200
         },
         {
            Header: t('price'),
            accessor: 'price',
            width: 200,
            sortToggleHandler: Events.sortToggleHandler
         }
      ],
      []
   )

   const data = useMemo(
      () =>
         productsList.map((product) => {
            const publish_date = new Date(product.createdAt).toLocaleDateString('en-US')

            return {
               id: <>{product.barcode}</>,
               photo: <Image src={`${api.preview}${product.preview}`} />,
               label: <>{product.description?.title}</>,
               publish_date: <Moment format="MM.DD.yyyy">{publish_date}</Moment>,
               category: <>{product.category.title}</>,
               total: <>{product.amount}</>,
               price: <>{product.price}</>,
               isChecked: activeProductsList.includes(product._id),
               selectedItems: selectedItems,
               disabled: activeItems && activeItems.includes(product._id)
            }
         }),
      [productsList, activeProductsList]
   )

   useEffect(() => {
      if (!discountPopup) {
         Requests.getProducts(0)
         // setProductsList([])
         Requests.getCategories()
         // setPage(0)
      } else {
         Requests.getProducts()
         Requests.getCategories()
      }
   }, [searchForm, tableSortBy, filterForm, page])

   // Append new support request data in data state
   useEffect(() => {
      if (!discountPopup) {
         setLoading(true)
         if (products?.meta?.totalCount) {
            setProductsList((productsList) => {
               return productsList.concat(products.data)
            })
            products?.meta?.totalCount <= productsList.length + products.data.length &&
               setLoading(false)
         }
         if (products?.meta?.totalCount == 0) {
            setLoading(false)
         }
      } else {
         setProductsList(products.data)
      }
   }, [products])

   useEffect(() => {
      setPage(0)
      setProductsList([])
   }, [filterForm?.value])

   useEffect(() => {
      activeItems && setActiveProductsList(activeItems)
   }, [activeItems])

   useEffect(() => {
      return () => {
         Utils.clearProducts()
      }
   }, [])

   return (
      <Popup
         backgroundClickListener={closeClickHandler}
         width="1158px"
         height="844px"
         backgroundColor={backgroundColor}>
         <FlexContainer
            direction="column"
            align="center"
            justify="center"
            style={{ padding: '40px 10px' }}
            gap="5px">
            <H2>{t('add.product')}</H2>
            <FlexContainer
               justify="space-around"
               style={{ marginTop: '33px' }}
               rowGap="20px">
               <SearchBar
                  name="query"
                  placeholder={t('search')}
                  value={searchForm.query}
                  onChange={Events.onChangeHandler}
               />
               <FlexContainer gap="30px" width="auto">
                  <DateInput
                     name="date_start"
                     value={searchForm.date_start}
                     onChange={Events.onChangeHandler}
                  />
                  <DateInput
                     name="date_end"
                     value={searchForm.date_end}
                     onChange={Events.onChangeHandler}
                  />
               </FlexContainer>
               <Select
                  styles={selectStyles}
                  placeholder={t('select.category')}
                  options={categories.data
                     .filter(
                        (item) =>
                           item.show &&
                           item.productsTotalCount &&
                           item.categoriesTotalCount == 0
                     )
                     .map((item) => ({
                        label: item.title,
                        value: item._id
                     }))}
                  value={filterForm}
                  onChange={Events.paginateDropDownChangeHandler}
                  isClearable
               />
            </FlexContainer>
            <TableContainer>
               {discountPopup ? (
                  <>
                     <Table
                        columns={columns}
                        data={data}
                        sortBy={tableSortBy}
                        checkboxClickHandler={Events.checkboxClickHandler}
                        headerCheckbox={Events.checkedAll}
                        checkAll={true}
                        visability={false}
                     />
                     <Pagination
                        page={page}
                        pageCount={
                           products.meta ? Math.ceil(products.meta.totalCount / 100) : 1
                        }
                        onPageChange={Events.onPageChangeHandler}
                     />
                  </>
               ) : (
                  <InfinityScroll
                     next={Events.nextProducts}
                     loading={!!loading && !!productsList.length}
                     preloader={
                        <PreloaderContainer>
                           <RelativePreloader size="50px" loading />
                        </PreloaderContainer>
                     }>
                     <Table
                        columns={columns}
                        data={data}
                        sortBy={tableSortBy}
                        checkboxClickHandler={Events.checkboxClickHandler}
                     />
                  </InfinityScroll>
               )}
            </TableContainer>
            <FlexContainer
               align="center"
               justify="center"
               gap="30px"
               style={{ paddingTop: '30px' }}>
               <Button theme="grey" style="transparant" onClick={closeClickHandler}>
                  {t('cancel')}
               </Button>
               <Button theme="green" onClick={Events.addProductClickHandler}>
                  {t('add')}
               </Button>
            </FlexContainer>
         </FlexContainer>
      </Popup>
   )
}

export default ProductPopup
