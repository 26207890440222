import { FC, useEffect } from 'react'

import { useInView } from 'react-intersection-observer'
import { AlignContainer } from '../Styled'
import { TInfinityScroll } from './types'

const InfinityScroll: FC<TInfinityScroll> = ({
   children,
   next,
   preloader,
   loading,
   treshold = 0
}) => {
   const { ref, inView } = useInView({
      /* Optional options */
      threshold: treshold,
      fallbackInView: true
   })

   useEffect(() => {
      inView && next()
   }, [inView])

   return (
      <>
         {children}
         {<AlignContainer ref={ref}>{preloader}</AlignContainer>}
      </>
   )
}

export default InfinityScroll
