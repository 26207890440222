import { TRoutes } from './types'
import {
   ConfigureRole,
   RolesCreatePage,
   RolesEditPage,
   RolesIndexPage
} from '../pages/Roles'
import { Navigate } from 'react-router-dom'
import { ClientOrderHistoryPage } from '../pages/Clients/pages/Edit/pages'

import {
   BannersIndexPage,
   BonusesIndexPage,
   CategoriesIndexPage,
   ChangelogIndexPage,
   ClientsIndexPage,
   CreateWorkerIndexPage,
   DeliveryIndexPage,
   EditArchiveIndexPage,
   EditCategoryIndexPage,
   EditCategoryProductCharacteristicIndexPage,
   EditCategoryProductIndexPage,
   EditCategoryProductRelatedProducts,
   EditCategoryProductVariationsIndexPage,
   EditHistoryIndexPage,
   EditNewCategoryIndexPage,
   EditNewProductsIndexPage,
   EditStatisticIndexPage,
   EditWorkerIndexPage,
   ClientEditIndexPage,
   GridBannersPage,
   HistoryIndexPage,
   LoginPage,
   ManageWorkersIndexPage,
   MetadataPage,
   NewCategories,
   NewProductsCharacteristicsIndexPage,
   NewProductsIndexPage,
   NewProductsRelatedProducts,
   NewProductsVariationsIndexPage,
   OrderArchiveIndexPage,
   OrderEditIndexPage,
   OrdersCurrentIndexPage,
   ProductsIndexPage,
   PromotionalProductsIndexPage,
   ReviewIndexPage,
   SeoTextPage,
   SettingsIndexPage,
   StatisticCharacteristicsIndexPage,
   StatisticIndexPage,
   StatisticRelatedProducts,
   StatisticVariationsIndexPage,
   SubscribersIndexPage,
   SupportIndexPage,
   ModerateChangesIndexPage,
   BusinessClients,
   EditBusinessClient,
   DiscountSystem,
   CharacteristicsIndexPage,
   VariationsIndexPage,
   ProductEditIndexPage,
   NewProductsEditVariationIndexPage,
   NotFoundedPage,
   BusinessCustomerRequest,
   UnpaidOrderPage,
   Calculator,
   CreateCalculator,
   CalculatorCategory,
   FirstStage,
   EditCalculator,
   Brands,
   AlternativeProducts,
   HistoryOrders
} from 'pages'
import { RelatedProducts } from 'pages/Products/pages/CurrentProducts/pages/Edit/pages/RelatedProducts'
import { EditVariationIndexPage } from 'pages/Products/pages/CurrentProducts/pages/Edit/pages/Variations/pages'
import { EditStatisticVariationIndexPage } from 'pages/Products/pages/Statistic/pages/Edit/pages/Variations/pages'

const Routes: TRoutes = {
   orders: {
      name: 'orders',
      path: '/orders',
      component: () => <Navigate to={'/orders/unpaid'} />
   },
   ordersCurrent: {
      name: 'ordersCurrent',
      path: '/orders/current',
      component: OrdersCurrentIndexPage
   },
   ordersUnpaid: {
      name: 'ordersUnpaid',
      path: '/orders/unpaid',
      component: UnpaidOrderPage
   },
   ordersNotifications: {
      name: 'ordersNotifications',
      path: '/orders/notifications',
      component: OrdersCurrentIndexPage
   },
   ordersArchive: {
      name: 'ordersArchive',
      path: '/orders/archive',
      component: OrderArchiveIndexPage
   },
   orderEdit: {
      name: 'orderEdit',
      path: '/orders/:id',
      component: OrderEditIndexPage
   },
   orderEditArchive: {
      name: 'orderEditArchive',
      path: '/orders/archive/:id',
      component: EditArchiveIndexPage
   },
   products: {
      name: 'products',
      path: '/products',
      component: ProductsIndexPage
   },
   statistic: {
      name: 'statistic',
      path: '/statistic',
      component: StatisticIndexPage
   },
   statisticEdit: {
      name: 'statisticEdit',
      path: '/statistic/page=:pageNumber/:id',
      component: EditStatisticIndexPage
   },
   statisticCharacteristics: {
      name: 'statisticCharacteristics',
      path: '/statisticCharacteristics/page=:pageNumber/:id',
      component: StatisticCharacteristicsIndexPage
   },
   statisticRelated: {
      name: 'statisticRelated',
      path: '/statisticRelated/page=:pageNumber/:id',
      component: StatisticRelatedProducts
   },
   statisticVariations: {
      name: 'statisticVariations',
      path: '/statisticVariations/page=:pageNumber/:id',
      component: StatisticVariationsIndexPage
   },
   statisticEditVariations: {
      name: 'statisticVariations',
      path: '/statisticVariation/page=:pageNumber/:id',
      component: EditStatisticVariationIndexPage
   },
   newProducts: {
      name: 'newProducts',
      path: '/products/newProducts',
      component: NewProductsIndexPage
   },
   editNewProducts: {
      name: 'editNewProducts',
      path: '/products/newProducts/page=:pageNumber/:id',
      component: EditNewProductsIndexPage
   },
   newProductsCharacteristics: {
      name: 'newProductsCharacteristics',
      path: '/products/newProducts/characteristics/page=:pageNumber/:id',
      component: NewProductsCharacteristicsIndexPage
   },
   newProductsRelatedProducts: {
      name: 'newProductsRelatedProducts',
      path: '/products/newProducts/related/page=:pageNumber/:id',
      component: NewProductsRelatedProducts
   },
   newProductsVariations: {
      name: 'newProductsVariations',
      path: '/products/newProducts/variations/page=:pageNumber/:id',
      component: NewProductsVariationsIndexPage
   },
   editNewProductsVariation: {
      name: 'editNewProductsVariation',
      path: '/products/newProducts/variation/page=:pageNumber/:id',
      component: NewProductsEditVariationIndexPage
   },
   promotionProducts: {
      name: 'promotionProducts',
      path: '/products/promotionProducts',
      component: PromotionalProductsIndexPage
   },
   productsCharacteristics: {
      name: 'characteristics',
      path: '/characteristics/page=:pageNumber/:id',
      component: CharacteristicsIndexPage
   },
   productsVariations: {
      name: 'variations',
      path: '/variations/page=:pageNumber/:id',
      component: VariationsIndexPage
   },
   productsRelated: {
      name: 'related',
      path: '/related/page=:pageNumber/:id',
      component: RelatedProducts
   },
   editProduct: {
      name: 'products',
      path: '/products/page=:pageNumber/:id',
      component: ProductEditIndexPage
   },
   editVariation: {
      name: 'variations',
      path: '/variation/page=:pageNumber/:id',
      component: EditVariationIndexPage
   },
   clients: {
      name: 'clients',
      path: '/clients',
      component: ClientsIndexPage
   },
   editClient: {
      name: 'editClient',
      path: '/clients/:id',
      component: ClientEditIndexPage
   },
   editClientHistory: {
      name: 'editClientHistory',
      path: '/clients/:id/history',
      component: ClientOrderHistoryPage
   },
   businessCustomerRequest: {
      name: 'businessCustomerRequest',
      path: '/businessCustomerRequest',
      component: BusinessCustomerRequest
   },
   catalog: {
      name: 'catalog',
      path: '/catalog',
      component: () => <Navigate to={'/catalog/categories'} />
   },
   catalogCategories: {
      name: 'catalogCategories',
      path: '/catalog/categories',
      component: CategoriesIndexPage
   },
   editCategory: {
      name: 'editCategory',
      path: '/catalog/categories/:id',
      component: EditCategoryIndexPage
   },
   editNewCategory: {
      name: 'editNewCategory',
      path: '/catalog/newCategories/:id',
      component: EditNewCategoryIndexPage
   },
   editCategoryProduct: {
      name: 'editCategoryProduct',
      path: '/catalog/edit/:id',
      component: EditCategoryProductIndexPage
   },
   editCategoryProductCharacteristics: {
      name: 'editCategoryProduct',
      path: '/catalog/characteristics/:id',
      component: EditCategoryProductCharacteristicIndexPage
   },
   editCategoryProductVariations: {
      name: 'editCategoryProduct',
      path: '/catalog/variations/:id',
      component: EditCategoryProductVariationsIndexPage
   },
   editCategoryProductRelated: {
      name: 'editCategoryProduct',
      path: '/catalog/related/:id',
      component: EditCategoryProductRelatedProducts
   },
   newCategories: {
      name: 'newCategories',
      path: '/catalog/newCategories',
      component: NewCategories
   },
   support: {
      name: 'support',
      path: '/support',
      component: SupportIndexPage
   },
   bonuses: {
      name: 'bonuses',
      path: '/bonuses',
      component: BonusesIndexPage
   },
   banners: {
      name: 'banners',
      path: '/banners',
      component: BannersIndexPage
   },
   bannersGrid: {
      name: 'bannersGrid',
      path: '/banners/grid',
      component: GridBannersPage
   },
   delivery: {
      name: 'delivery',
      path: '/delivery',
      component: DeliveryIndexPage
   },
   roles: {
      name: 'roles',
      path: '/roles',
      component: RolesIndexPage
   },
   createRole: {
      name: 'createRole',
      path: '/roles/create',
      component: RolesCreatePage
   },
   editRole: {
      name: 'editRole',
      path: '/roles/:id',
      component: RolesEditPage
   },
   configureRole: {
      name: 'configureRole',
      path: '/roles/configure',
      component: ConfigureRole
   },
   settings: {
      name: 'settings',
      path: '/settings',
      component: SettingsIndexPage
   },
   login: {
      name: 'login',
      path: '/login',
      component: LoginPage
   },
   seo: {
      name: 'seo',
      path: '/seo',
      component: MetadataPage
   },
   metadata: {
      name: 'metadata',
      path: '/seo/metadata',
      component: MetadataPage
   },
   seoText: {
      name: 'seoText',
      path: '/seo/text',
      component: SeoTextPage
   },
   notFounded: {
      name: 'notFounded',
      path: '*',
      component: () => <Navigate to={'/login'} />
   },
   notFoundedPage: {
      name: 'notFoundedPage',
      path: '*',
      component: NotFoundedPage
   },
   notFoundedAuth: {
      name: 'notFoundedAuth',
      path: '*',
      component: () => <Navigate to={'/orders'} />
   },
   manageWorkers: {
      name: 'manageWorkers',
      path: '/workers',
      component: ManageWorkersIndexPage
   },
   createWorker: {
      name: 'createWorker',
      path: '/createWorker',
      component: CreateWorkerIndexPage
   },
   editWorker: {
      name: 'editWorker',
      path: '/workers/:id',
      component: EditWorkerIndexPage
   },
   reviewWorker: {
      name: 'reviewWorker',
      path: '/reviewWorker/:id',
      component: ReviewIndexPage
   },
   subscribers: {
      name: 'subscribers',
      path: '/subscribers',
      component: SubscribersIndexPage
   },
   subscribersHistory: {
      name: 'subscribersHistory',
      path: '/history',
      component: HistoryIndexPage
   },
   editHistory: {
      name: 'editHistory',
      path: '/history/:id',
      component: EditHistoryIndexPage
   },
   changelog: {
      name: 'changelog',
      path: '/changelog',
      component: ChangelogIndexPage
   },
   moderateChanges: {
      name: 'moderateChanges',
      path: '/changelog/page=:page/id=:id',
      component: ModerateChangesIndexPage
   },
   businessClients: {
      name: 'businessClients',
      path: '/businessClients',
      component: BusinessClients
   },
   editBusinessClients: {
      name: 'editBusinessClients',
      path: '/businessClients/:id',
      component: EditBusinessClient
   },
   businessClientsHistoryOrders: {
      name: 'businessClientsHistoryOrders',
      path: '/businessClients/:id/historyOrders',
      component: HistoryOrders
   },
   discountSystem: {
      name: 'discountSystem',
      path: '/discountSystem/:id',
      component: DiscountSystem
   },
   calculator: {
      name: 'calculator',
      path: '/calculator',
      component: Calculator
   },
   createCalculator: {
      name: 'createCalculator',
      path: '/calculator/createCalculator',
      component: CreateCalculator
   },
   calculatorCategory: {
      name: 'categoriesForTheCalculator',
      path: '/categoriesForTheCalculator',
      component: CalculatorCategory
   },
   firstStage: {
      name: 'firstStage',
      path: '/calculator/first-stage',
      component: FirstStage
   },
   editCalculator: {
      name: 'editCalculator',
      path: '/calculator/:id',
      component: EditCalculator
   },
   brands: {
      name: 'brands',
      path: '/brands',
      component: Brands
   },
   alternativeProducts: {
      component: AlternativeProducts,
      name: 'alternativeProducts',
      path: '/alternativeProducts/:id'
   }
}

export default Routes
