import { Checkbox, FlexContainer, Input } from 'components'
import React, { FC } from 'react'
import {
   AddText,
   CheckboxText,
   ClickbleText,
   Container,
   DeleteText,
   HeaderText
} from '../../styled'
import { assets } from 'assets'
import { t } from 'i18next'
import { Card } from '../Card'
import { TProductsTableProps } from './types'

export const ProductsTable: FC<TProductsTableProps> = ({
   selectedProducts,
   setSelectedProducts,
   Events,
   isChecked,
   form,
   customerDiscount,
   customerId,
   loadMoreClick,
   isEndList
}) => {
   // console.log(selectedProducts)

   return (
      <>
         {selectedProducts?.length > 0 && (
            <FlexContainer margin="30px" gap="30px">
               <FlexContainer width="210px" direction="column" gap="15px">
                  <HeaderText>{t('discount.for.products')}</HeaderText>

                  <FlexContainer align="center" gap="15px">
                     <img src={assets.PLUS_ICON_BLUE} />

                     <AddText onClick={Events.setProductsPopupVisiablity}>
                        {t('add.products.discount')}
                     </AddText>
                  </FlexContainer>

                  <FlexContainer align="center" gap="15px">
                     <img src={assets.DELETE_ICON} />

                     <DeleteText onClick={Events.clearProducts}>
                        {t('delete.products')}
                     </DeleteText>
                  </FlexContainer>

                  <FlexContainer
                     gap="8px"
                     style={{ paddingTop: isChecked ? '60px' : '105px' }}
                     align="center">
                     <Checkbox
                        checked={isChecked}
                        onChange={Events.checkedStateHandler}
                     />

                     <CheckboxText style={{ width: '163px' }}>
                        {t('common.discount.for.products')}
                     </CheckboxText>

                     {isChecked && (
                        <Input
                           max={99}
                           name="discountPriceProducts"
                           onChange={(e) => {
                              const { value } = e.target as HTMLInputElement

                              if (
                                 value === '' ||
                                 (parseFloat(value) >= 0 && parseFloat(value) < 100)
                              ) {
                                 Events.inputChangeHandler(e)
                              }
                           }}
                           value={form.discountPriceProducts}
                           placeholder={t('for.all.products')}
                        />
                     )}
                  </FlexContainer>
               </FlexContainer>

               <Container>
                  {selectedProducts?.map((item, index) => (
                     <React.Fragment key={item._id}>
                        <Card
                           productItem={item}
                           selectedProducts={selectedProducts}
                           setSelectedProducts={setSelectedProducts}
                           discountValue={isChecked ? form.discountPriceProducts : ''}
                           defaultDiscountValue={item?.discount ? item.discount + '' : ''}
                           customerDiscount={customerDiscount}
                           index={index}
                           isChecked={isChecked}
                        />
                     </React.Fragment>
                  ))}
                  {!isEndList ? (
                     <div
                        style={{
                           alignSelf: 'center',
                           justifySelf: 'center',
                           width: '200px'
                        }}>
                        <div
                           style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                           }}>
                           <p>
                              {t('checked') +
                                 ` ${selectedProducts.length} ` +
                                 t('from.all') +
                                 ` ${customerDiscount?.meta?.totalCount}`}
                           </p>
                           <ClickbleText onClick={loadMoreClick}>
                              {t('show.more')}
                           </ClickbleText>
                        </div>
                     </div>
                  ) : null}
               </Container>
            </FlexContainer>
         )}
      </>
   )
}
