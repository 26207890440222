import { AdminPanelContainer, Button, FlexContainer } from 'components'
import { Header } from 'pages/Calculator/components'
import { useTranslation } from 'react-i18next'
import { useGetBrandsInfinity } from 'store'
import { BrandsList, BrendItem, LoadMoreButton } from './styled'
import { CiImageOff } from 'react-icons/ci'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { ApiBrandService } from 'store/brand/api.service'
import { useTypedSelector } from 'hooks'
import { TBrand } from 'store/brand/types'
import { toast } from 'react-toastify'
import { api } from 'config'
import { useInView } from 'react-intersection-observer'
import { useEffect, useRef, useState } from 'react'

const LIMIT = 20

export const Brands = () => {
   const { t } = useTranslation()
   const { accessToken } = useTypedSelector((state) => state.user)

   const brandsListRef = useRef()

   const { brandsList, brandsRes, loadMoreClick, size, mutate, totalCount } =
      useGetBrandsInfinity({
         limit: LIMIT,
         sortBy: 'order',
         order: 1
      })

   const noVisibleButton = totalCount <= LIMIT * size + 1

   const updateLocalBrandsOrder = (newOrderedList: TBrand[]) => {
      function splitIntoPages() {
         let pages = []
         for (let i = 0; i < newOrderedList.length; i += LIMIT) {
            pages.push(newOrderedList.slice(i, i + LIMIT))
         }
         return pages
      }

      const updatedBrands = splitIntoPages().map((pageItems) => ({
         data: pageItems,
         meta: brandsRes?.[0].meta
      }))

      mutate(updatedBrands, false)
   }

   const onDragEnd = async (result: DropResult) => {
      if (!result.destination) {
         return
      }
      const items = Array.from(brandsList)
      const [reorderedItem] = items.splice(result.source.index, 1)
      items.splice(result.destination.index, 0, reorderedItem)

      items.forEach((item, index) => {
         item.order = index + 1
      })

      const orderBrands = items.map((el) => ({ order: el.order, id: el._id }))
      updateLocalBrandsOrder(items)
      await ApiBrandService.editOrderBrands(orderBrands, accessToken || '')
      toast.success(t('brandsOrderChanged'))
      mutate()
   }

   return (
      <DragDropContext onDragEnd={onDragEnd}>
         <AdminPanelContainer Header={<Header title={<h2>{t('brands')}</h2>} />}>
            <Droppable droppableId="brands">
               {(provided) => (
                  <BrandsList
                     {...(provided.droppableProps, brandsListRef)}
                     ref={provided.innerRef}>
                     {brandsList.map((brand, index) => {
                        const src =
                           brand?.image && brand?.image.length > 6
                              ? `${api.brandsPath}${brand?.image}`
                              : null
                        return (
                           <Draggable
                              key={brand?._id}
                              draggableId={brand?._id || ''}
                              index={index}>
                              {(provided) => (
                                 <BrendItem
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}>
                                    <span>{index + 1}</span>
                                    <p>{brand?.title}</p>
                                    {src ? <img alt="brand" src={src} /> : <CiImageOff />}
                                 </BrendItem>
                              )}
                           </Draggable>
                        )
                     })}
                     {provided.placeholder}
                  </BrandsList>
               )}
            </Droppable>
            {!noVisibleButton && (
               <FlexContainer justify="center" padding="15px">
                  <LoadMoreButton onClick={loadMoreClick}>
                     {t('view.another')}
                  </LoadMoreButton>
               </FlexContainer>
            )}
         </AdminPanelContainer>
      </DragDropContext>
   )
}
