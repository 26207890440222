import { Checkbox, FlexContainer, Input } from 'components'
import React, { FC, useEffect, useMemo, useState } from 'react'
import {
   AddText,
   CheckboxText,
   ClickbleText,
   Container,
   DeleteText,
   HeaderText
} from '../../styled'
import { assets } from 'assets'
import { Card } from '../Card'
import { t } from 'i18next'
import { TBrandsTableProps } from './types'
import { PAGE_LIMIT } from '../../consts'

export const BrandsTable: FC<TBrandsTableProps> = ({
   selectedBrands,
   isCheckedBrands,
   customerDiscount,
   form,
   Events,
   setSelectedBrands,
   isEndList,
   loadMoreClick,
   customerId
}) => {
   return (
      <>
         {selectedBrands.length > 0 && (
            <FlexContainer margin="0 0 0 30px" gap="30px">
               <FlexContainer width="210px" direction="column" gap="15px">
                  <HeaderText>{t('discount.for.brands')}</HeaderText>

                  <FlexContainer align="center" gap="15px">
                     <img src={assets.PLUS_ICON_BLUE} />

                     <AddText onClick={Events.setBrandsPopupVisiablity}>
                        {t('add.brands')}
                     </AddText>
                  </FlexContainer>

                  <FlexContainer align="center" gap="15px">
                     <img src={assets.DELETE_ICON} />
                     <DeleteText onClick={Events.clearBrands}>
                        {t('delete.brands')}
                     </DeleteText>
                  </FlexContainer>

                  <FlexContainer
                     gap="8px"
                     style={{ paddingTop: isCheckedBrands ? '60px' : '105px' }}
                     align="center">
                     <Checkbox
                        checked={isCheckedBrands}
                        onChange={Events.checkedBrandsStateHandler}
                     />

                     <CheckboxText style={{ width: '163px' }}>
                        {t('common.discount.for.brands')}
                     </CheckboxText>

                     {isCheckedBrands && (
                        <Input
                           name="discountPriceBrands"
                           onChange={(e) => {
                              const { value } = e.target as HTMLInputElement

                              if (
                                 value === '' ||
                                 (parseFloat(value) >= 0 && parseFloat(value) < 100)
                              ) {
                                 Events.inputChangeHandler(e)
                              }
                           }}
                           value={form.discountPriceBrands}
                           placeholder={t('for.all.brands')}
                        />
                     )}
                  </FlexContainer>
               </FlexContainer>

               <Container>
                  {selectedBrands.map((brand, index) => (
                     <React.Fragment key={brand._id + index}>
                        <Card
                           brandItem={brand}
                           selectedBrands={selectedBrands}
                           setSelectedBrands={setSelectedBrands}
                           discountValue={isCheckedBrands ? form.discountPriceBrands : ''}
                           defaultDiscountValue={
                              brand?.discount ? brand?.discount + '' : ''
                           }
                           isChecked={isCheckedBrands}
                           customerDiscount={customerDiscount}
                           index={index}
                        />
                     </React.Fragment>
                  ))}
                  {!isEndList ? (
                     <div
                        style={{
                           alignSelf: 'center',
                           justifySelf: 'center',
                           width: '200px'
                        }}>
                        <p>
                           {t('checked') +
                              ` ${selectedBrands?.length} ` +
                              t('from.all') +
                              ` ${customerDiscount?.meta?.totalCount}`}
                        </p>
                        <ClickbleText onClick={loadMoreClick}>
                           {t('show.more')}
                        </ClickbleText>
                     </div>
                  ) : null}
               </Container>
            </FlexContainer>
         )}
      </>
   )
}
