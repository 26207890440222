import {
   AdminPanelContainer,
   FlexContainer,
   Header,
   HoverList,
   Pagination,
   SubPagesBar,
   Table,
   TColumnTable,
   TSort
} from 'components'
import { useNavigate, useParams } from 'react-router-dom'
import { columns, sections, useSearchParams } from './helpers'
import { useMemo } from 'react'
import { useTypedSelector } from 'hooks'
import { useGetClient } from 'store/client/api.service'
import { getUserSelector } from 'store'
import Moment from 'react-moment'
import { useGetgetOrdersByCustomer } from 'store/order/api.service'
import { useTranslation } from 'react-i18next'
import { ELocales } from 'enums'
import { TOrder } from 'store/order/types'
import { TDescription } from 'store/product/types'

const ITEM_PER_PAGE = 10

export const HistoryOrders = () => {
   const { t, i18n } = useTranslation()
   const { id } = useParams()
   const navigate = useNavigate()

   const { params, oSortChange, onPageChange } = useSearchParams()

   const { accessToken } = useTypedSelector(getUserSelector)
   const token = 'Bearer ' + accessToken

   const { userData, isLoading: userLoading } = useGetClient({ _id: id || '', token })

   const user = userData?.data?.data

   const { ordersData, isLoading } = useGetgetOrdersByCustomer({
      _id: id || '',
      token,
      page: params.page,
      limit: ITEM_PER_PAGE,
      lang: i18n.language as ELocales,
      order: params.order,
      sortBy: params.sortBy
   })

   const data = ordersData?.data?.data?.map((order: TOrder) => {
      return {
         id: order.id,
         createdAt: (
            <Moment format="DD.MM.YYYY HH:mm">
               {new Date(order.createdAt as string)}
            </Moment>
         ),
         order: (
            <FlexContainer align="center" justify="center">
               <FlexContainer
                  direction="column"
                  align="flex-start"
                  justify="flex-start"
                  gap="4px"
                  width="170px">
                  <p style={{ textAlign: 'left' }}>
                     {(order.items[0]?.product?.description as TDescription)?.title}
                  </p>
                  {!!order.items.slice(1).length && (
                     <HoverList
                        items={order.items
                           .slice(1)
                           .map(
                              (item) => (item.product?.description as TDescription)?.title
                           )}
                     />
                  )}
               </FlexContainer>
            </FlexContainer>
         ),
         paymentStatus: t(order.paymentStatus),
         orderStatus: t(order.orderStatus)
      }
   })

   const tableSortBy = useMemo(() => {
      {
         if (!params.order) {
            return { id: null, desc: false }
         }
         return { id: params.sortBy, desc: params.order > 0 }
      }
   }, [params])
   return (
      <AdminPanelContainer
         Header={
            <Header
               title={t('edit')}
               backButtonClickHandler={() => navigate(-1)}
               dates={[
                  {
                     info: t('createdAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(user?.createdAt as string)}
                        </Moment>
                     )
                  },
                  {
                     info: t('updatedAt.date'),
                     date: (
                        <Moment format="DD.MM.YYYY HH:mm">
                           {new Date(user?.updatedAt as string)}
                        </Moment>
                     )
                  }
               ]}
            />
         }
         loading={isLoading || userLoading}>
         <SubPagesBar sections={sections(id || '', navigate)} />
         <Table
            columns={columns(oSortChange)}
            data={data}
            sortBy={tableSortBy}
            editable
            editClickHandler={(e, i) =>
               navigate(`/orders/${ordersData?.data?.data?.[i]?._id}`)
            }
         />

         <Pagination
            page={params.page}
            pageCount={
               ordersData?.data?.meta
                  ? Math.ceil(ordersData?.data?.meta.totalCount / ITEM_PER_PAGE)
                  : 1
            }
            onPageChange={({ selected }) => onPageChange(selected)}
         />
      </AdminPanelContainer>
   )
}
