import { AxiosResponse } from 'axios'
import { TGetCustomerByIdResponse } from 'store/client/types'
import { TResponse } from 'store/types'

export const transformResToData = (res: AxiosResponse<TResponse, any>[] | undefined) => {
   const discountData = res ? res.flat() : []
   const discountList: TGetCustomerByIdResponse['data'] = discountData?.length
      ? discountData?.flatMap((item) => item?.data.data)
      : []
   return discountList
}
