import { ELocales } from 'enums'
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { api } from '../../config'
import { useLocalization } from '../../hooks'
import { productActions, useGetBrandsInfinity } from '../../store'
import { Button } from '../Button'
import { InfinityScroll } from '../InfinityScroll'
import { Popup } from '../Popup'
import { RelativePreloader } from '../Preloader'
import { SearchBar } from '../SearchBar'
import { FlexContainer, H2 } from '../Styled'
import { Table, TColumnTable, TSort } from '../Table'
import { PER_PAGE_ITEMS } from './consts'
import { Image, PreloaderContainer, TableContainer } from './styled'
import { TSelectProductPopup } from './types'

const ProductPopup: FC<TSelectProductPopup> = ({
   closeClickHandler,
   addProductClickHandler,
   activeItems,

   similar
}) => {
   const { t } = useTranslation()

   const [activeProductsList, setActiveProductsList] = useState<string[]>([])

   const [locale] = useLocalization()

   const [searchForm, setSearchForm] = useState({
      regex: '',
      date_start: '',
      date_end: ''
   })

   const [sortParamsForm, setSortParamsForm] = useState({
      sortBy: '',
      order: '' as TSort
   })
   const { brandsList, isLoading, isEndList, loadMoreClick } = useGetBrandsInfinity({
      limit: PER_PAGE_ITEMS,
      lang: locale as ELocales,
      ...(searchForm.regex ? { search: searchForm.regex } : {})
   })
   const unicList = brandsList?.filter((el) => !activeItems?.includes(el._id))
   const tableSortBy = useMemo(() => {
      {
         if (!sortParamsForm.order) {
            return { id: null, desc: false }
         }
         return { id: sortParamsForm.sortBy, desc: sortParamsForm.order > 0 }
      }
   }, [sortParamsForm])

   const Events = {
      onChangeHandler: (e: React.SyntheticEvent) => {
         const input = e.target as HTMLInputElement
         setSearchForm({ ...searchForm, [input.name]: input.value })
      },
      sortToggleHandler: (sortBy: string, order: TSort) => {
         setSortParamsForm({ sortBy, order })
      },
      checkboxClickHandler: (e: SyntheticEvent, p: any, t: any, index: number) => {
         const isChecked = (e.target as HTMLInputElement).checked
         let id: string = unicList[index]?._id
         setActiveProductsList((list) => {
            if (isChecked && list.includes(id)) return list

            if (isChecked && id) return [...new Set([...list, id])]

            return list.filter((item) => item !== id)
         })
      },
      nextBrands: () => {
         loadMoreClick()
      },
      addProductClickHandler: () => {
         addProductClickHandler &&
            addProductClickHandler(
               unicList.filter((element) => activeProductsList.includes(element._id))
            )
      },
      checkedAll: (
         e: SyntheticEvent,
         hasCheckboxesActiveState: boolean,
         checkedItemsIndex: number[]
      ) => {
         // setRemoveButtonState(hasCheckboxesActiveState)
         // setSelectedItems(checkedItemsIndex)

         for (let index of checkedItemsIndex) {
            activeProductsList.push(unicList[index]._id)
         }
      }
   }

   const columns: TColumnTable[] = useMemo(
      () => [
         {
            Header: t('logo'),
            accessor: 'photo'
         },
         {
            Header: t('label'),
            accessor: 'label',
            width: 350
         },
         {
            Header: t('total'),
            accessor: 'total',
            width: 350
         },
         {
            Header: t('sells'),
            accessor: 'sales',
            width: 350
         }
      ],
      []
   )

   const data = useMemo(
      () =>
         unicList?.map((brand) => {
            return {
               photo: <Image src={`${api.preview}${brand.image}`} />,
               label: <>{brand.title}</>,
               isChecked: activeProductsList.includes(brand._id),
               total: <>{brand?.productsCount}</>,
               sales: <>{brand?.sales}</>
            }
         }),
      [unicList, activeProductsList]
   )

   return (
      <Popup backgroundClickListener={closeClickHandler} width="1158px" height="844px">
         <FlexContainer
            direction="column"
            align="center"
            justify="center"
            style={{ padding: '40px 10px' }}
            gap="5px">
            <H2>{t('add.brand')}</H2>
            <FlexContainer
               justify="space-around"
               style={{ marginTop: '33px' }}
               rowGap="20px">
               <SearchBar
                  name="regex"
                  placeholder={t('search')}
                  value={searchForm.regex}
                  onChange={Events.onChangeHandler}
               />
            </FlexContainer>
            <TableContainer>
               <InfinityScroll
                  next={Events.nextBrands}
                  loading={isLoading}
                  preloader={
                     !isEndList ? (
                        <PreloaderContainer>
                           <RelativePreloader size="50px" loading />
                        </PreloaderContainer>
                     ) : (
                        <></>
                     )
                  }>
                  <Table
                     columns={columns}
                     data={data}
                     sortBy={tableSortBy}
                     checkboxClickHandler={Events.checkboxClickHandler}
                     headerCheckbox={Events.checkedAll}
                     visability={false}
                  />
               </InfinityScroll>
            </TableContainer>
            <FlexContainer
               align="center"
               justify="center"
               gap="30px"
               style={{ paddingTop: '30px' }}>
               <Button theme="grey" style="transparant" onClick={closeClickHandler}>
                  {t('cancel')}
               </Button>
               <Button theme="green" onClick={Events.addProductClickHandler}>
                  {t('add')}
               </Button>
            </FlexContainer>
         </FlexContainer>
      </Popup>
   )
}

export default ProductPopup
