import { AxiosResponse } from 'axios'
import { ELocales } from '../../enums'
import { HttpService } from '../../services'
import { TGetAdminRequestPayload } from '../admin/types'
import { TResponse } from '../types'
import { CLIENT_URL } from './config'
import {
   TCreateCustomerDiscountBulkRequestPayload,
   TCreateCustomerDiscountRequestPayload,
   TDeleteCustomerDiscountBulkRequestPayload,
   TEditClientRequestPayload,
   TGetBusinessClientsRequestPayload,
   TGetBusinessCustomerRequestPayloadRequest,
   TGetClientRequestPayload,
   TGetClientsRequestPayload,
   TGetCustomerByIdResponse,
   TGetCustomerDiscountByIdRequestPayload,
   TUpdateCustomerDiscountBulkRequestPayload
} from './types'
import useSWR from 'swr'
import useSWRInfinite from 'swr/infinite'

export class ApiClientService extends HttpService {
   static getClients({
      token,
      limit = 0,
      page = 0,
      lang = ELocales.ru,
      dateStart = '',
      dateEnd = '',
      searchValue = '',
      sortBy = '',
      order = ''
   }: TGetClientsRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CLIENT_URL.getClients}?limit=${limit}&skip=${
            page * limit
         }&lang=${lang}&regex=${searchValue}&start=${dateStart}&end=${dateEnd}&sortBy=${sortBy}&order=${order}`,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static getBusinessClients({
      token,
      limit = 0,
      page = 0,
      lang = ELocales.ru,
      ...params
   }: TGetBusinessClientsRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CLIENT_URL.getClients}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            skip: page * limit,
            lang,
            ...params
         }
      })

      return response
   }

   static getClient({
      token,
      _id
   }: TGetClientRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CLIENT_URL.getClient}/${_id}`,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static getClientAddresses({
      token,
      _id
   }: TGetAdminRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CLIENT_URL.getClientAddress}/${_id}`,
         method: 'GET',
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static editClient({
      token,
      _id,
      data
   }: TEditClientRequestPayload): Promise<AxiosResponse<TResponse>> {
      const response = this.request({
         url: `${CLIENT_URL.editClient}/${_id}`,
         method: 'PATCH',
         data,
         headers: {
            Authorization: token
         }
      })

      return response
   }

   static createCustomerDiscount({
      token,
      data
   }: TCreateCustomerDiscountRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CLIENT_URL.createCustomerDiscount}`,
         method: 'POST',
         data,
         headers: {
            Authorization: token
         }
      })
   }

   static createCustomerDiscountBulk({
      token,
      createdData
   }: TCreateCustomerDiscountBulkRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CLIENT_URL.createCustomerDiscountBulk}`,
         method: 'POST',
         data: createdData,
         headers: {
            Authorization: token
         }
      })
   }

   static getCustomerDiscountById({
      token,
      _id,
      lang,
      page,
      limit,
      type
   }: TGetCustomerDiscountByIdRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CLIENT_URL.getCustomerDiscountById(_id)}`,
         method: 'GET',
         headers: {
            Authorization: token
         },
         params: {
            customer: _id,
            lang: lang,
            skip: page * limit,
            type,
            limit
         }
      })
   }

   static updateCustomerDiscountBulk({
      token,
      updatedData
   }: TUpdateCustomerDiscountBulkRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CLIENT_URL.updateCustomerDiscountBulk}`,
         method: 'PATCH',
         data: updatedData,
         headers: {
            Authorization: token
         }
      })
   }

   static deleteCustomerDiscountBulk({
      token,
      _id
   }: TDeleteCustomerDiscountBulkRequestPayload): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CLIENT_URL.deleteCustomerDiscountBulk}`,
         method: 'DELETE',
         data: { _id: _id },
         headers: {
            Authorization: token
         }
      })
   }

   static getBusinessCustomerRequest({
      token,
      limit,
      page,
      lang
   }: TGetBusinessCustomerRequestPayloadRequest): Promise<AxiosResponse<TResponse>> {
      return this.request({
         url: `${CLIENT_URL.getBusinessCustomerRequest}`,
         method: 'GET',
         headers: {
            Authorization: token,
            'Nest-Cache': 'no-cache'
         },
         params: {
            skip: page * limit,
            limit,
            lang
         }
      })
   }
}

export const useGetClient = (value: { _id: string; token: string }) => {
   const {
      data: userData,
      mutate,
      isLoading
   } = useSWR(
      value._id && value.token ? ['getClient', value] : null,
      ([, p]) => ApiClientService.getClient(p),
      {
         revalidateOnFocus: false,
         keepPreviousData: true
      }
   )
   return { userData, mutate, isLoading }
}

export const useGetCustomerDiscount = (
   params: TGetCustomerDiscountByIdRequestPayload
) => {
   const {
      data: discountdata,
      mutate,
      isLoading
   } = useSWR(
      ['getDiscountBrands', params],
      ([, par]) => ApiClientService.getCustomerDiscountById(par),
      { revalidateOnFocus: false }
   )
   return { discountdata, mutate, discountBrandsLoading: isLoading }
}

export const useGetCustomerDiscountInfinite = (
   params: TGetCustomerDiscountByIdRequestPayload,
   onSuccess?: (data: AxiosResponse<TResponse, any>[] | undefined) => void
) => {
   const getKey = (pageIndex: number, previousPageData: any) => {
      if (previousPageData && !previousPageData.data?.data?.length) return null
      return [`getDiscountBrands`, { ...params, page: pageIndex }]
   }

   const { data, mutate, isLoading, size, setSize, isValidating } = useSWRInfinite(
      getKey,
      ([, par]: [string, TGetCustomerDiscountByIdRequestPayload]) => {
         return ApiClientService.getCustomerDiscountById(par)
      },
      {
         revalidateOnFocus: false,
         keepPreviousData: true,
         revalidateFirstPage: false,
         onSuccess
      }
   )
   const totalCount = data?.[0]?.data.meta?.totalCount as number
   const discountData = data ? data.flat() : []

   const discountList: TGetCustomerByIdResponse['data'] = discountData?.length
      ? discountData?.flatMap((item) => item?.data.data)
      : []

   const isEndList = totalCount <= discountList.length
   const loadMoreClick = () => {
      setSize((prev) => prev + 1)
   }
   const meta: TGetCustomerByIdResponse['meta'] = data?.[0]?.data.meta

   return {
      discountData: { data: discountList, meta },
      discountList,
      totalCount,
      mutate,
      discountBrandsLoading: isLoading || isValidating,
      loadMoreClick,
      isEndList
   }
}
