import { TColumnTable, TSection, TSort } from 'components'
import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'

export const sections = (id: string, navigate: NavigateFunction): TSection[] =>
   useMemo(
      () => [
         {
            title: t('main.data'),
            onClickHandler: () => {
               navigate(`/businessClients/${id}`)
            }
         },
         {
            title: t('orders.history'),
            active: true
         },
         {
            title: t('discount.system'),
            onClickHandler: () => {
               navigate(`/discountSystem/${id}`)
            }
         }
      ],
      []
   )

type TParams = {
   order: TSort
   sortBy: string
   page: number
}
export const useSearchParams = () => {
   const [params, setParams] = useState<TParams>({
      sortBy: 'createdAt',
      order: -1,
      page: 0
   })

   const onPageChange = (page: number) => setParams((prev) => ({ ...prev, page }))
   const onOrderChange = (order: TSort) => setParams((prev) => ({ ...prev, order }))
   const oSortChange = (sortBy: string) =>
      setParams((prev) => ({
         ...prev,
         sortBy,
         order: sortBy === prev.sortBy ? (prev.order === 1 ? -1 : 1) : prev.order
      }))

   return { onPageChange, oSortChange, onOrderChange, params }
}

export const columns = (oSortChange: (v: string) => void): TColumnTable[] =>
   useMemo(
      () => [
         {
            Header: t('id'),
            accessor: 'id',
            width: 250,
            sortToggleHandler: oSortChange
         },
         {
            Header: t('date'),
            accessor: 'createdAt',
            width: 250,
            sortToggleHandler: oSortChange
         },
         {
            Header: t('order'),
            accessor: 'order',
            width: 250
         },
         {
            Header: t('payment'),
            accessor: 'paymentStatus',
            width: 350,
            sortToggleHandler: oSortChange
         },
         {
            Header: t('order.status'),
            accessor: 'orderStatus',
            width: 250,
            sortToggleHandler: oSortChange
         }
      ],
      []
   )
