import { colors } from 'enums'
import styled from 'styled-components'
import { FONT } from 'utils'

export const RelatedProductsContainer = styled.div`
   display: flex;
   flex-wrap: nowrap;
   width: 100%;
   overflow-x: auto;
   gap: 17px;
   padding-bottom: 15px;
`

type RelatedProductsProps = {
   brandWidth?: string
   brandHeight?: string
}

export const RelatedProductWrapper = styled.div<RelatedProductsProps>`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   position: relative;
   font-size: 12px;
   border: 1px solid ${colors.table_grey};
   border-radius: 6px;
   padding: 12px 8px;
   min-width: 210px;
   width: ${({ brandWidth }) => (brandWidth ? brandWidth : '210px')};
   min-height: ${({ brandHeight }) => (brandHeight ? brandHeight : '288px')};
   .product-delete {
      width: 12px;
      height: 12px;
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
   }
   .product-img {
      width: 46px;
      height: 46px;
      object-fit: contain;
      align-self: center;
   }
   .product-code {
      font-weight: 500;
      padding: 12px 0 6px;
   }
   .product-name {
      ${FONT({ weight: '400', size: '14px', color: '#222222' })}
   }
   .brand-name {
      ${FONT({ weight: '500', size: '14px', color: '#222222' })}
      cursor: pointer;
      align-self: center;
      padding-bottom: 100px;
   }
   .product-barcode {
      ${FONT({ weight: '400', size: '14px', color: '#818181' })}
   }
   .product-price {
      ${FONT({ weight: '500', size: '14px', color: '#222222' })}

      &__old {
         color: ${colors.grey};
         text-decoration: line-through;
      }
      &__new {
      }
   }
`

export const Container = styled.div`
   // display: grid;
   margin-top: 30px;
   margin-left: 44px;
   margin-bottom: 30px;
   // grid-template-columns: 1fr 1fr 1fr;
   // grid-column-gap: 60px;
`
