import { useTypedSelector } from 'hooks'
import { FC } from 'react'

import { Route, Routes } from 'react-router-dom'
import { getUserSelector } from 'store'

import routes from './routes'

const AuthRoutes: FC = () => {
   const { role } = useTypedSelector(getUserSelector)

   return (
      <Routes>
         {role?.permissions.find((permission) => permission == 'Order') ? (
            <Route path={routes.orders.path} element={<routes.orders.component />} />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Order') ? (
            <Route
               path={routes.ordersUnpaid.path}
               element={<routes.ordersUnpaid.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Order') ? (
            <Route
               path={routes.ordersCurrent.path}
               element={<routes.ordersCurrent.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Order') ? (
            <Route
               path={routes.ordersNotifications.path}
               element={<routes.ordersNotifications.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Order') ? (
            <Route
               path={routes.ordersArchive.path}
               element={<routes.ordersArchive.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Order') ? (
            <Route
               path={routes.orderEditArchive.path}
               element={<routes.orderEditArchive.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Order') ? (
            <Route
               path={routes.orderEdit.path}
               element={<routes.orderEdit.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route path={routes.products.path} element={<routes.products.component />} />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.newProducts.path}
               element={<routes.newProducts.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.editNewProducts.path}
               element={<routes.editNewProducts.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.newProductsCharacteristics.path}
               element={<routes.newProductsCharacteristics.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.newProductsRelatedProducts.path}
               element={<routes.newProductsRelatedProducts.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.newProductsVariations.path}
               element={<routes.newProductsVariations.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.editNewProductsVariation.path}
               element={<routes.editNewProductsVariation.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.promotionProducts.path}
               element={<routes.promotionProducts.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.productsCharacteristics.path}
               element={<routes.productsCharacteristics.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.productsVariations.path}
               element={<routes.productsVariations.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.productsRelated.path}
               element={<routes.productsRelated.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.editProduct.path}
               element={<routes.editProduct.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.editVariation.path}
               element={<routes.editVariation.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.statistic.path}
               element={<routes.statistic.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.statisticEdit.path}
               element={<routes.statisticEdit.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.statisticCharacteristics.path}
               element={<routes.statisticCharacteristics.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.statisticRelated.path}
               element={<routes.statisticRelated.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.statisticVariations.path}
               element={<routes.statisticVariations.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.statisticEditVariations.path}
               element={<routes.statisticEditVariations.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.changelog.path}
               element={<routes.changelog.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Product') ? (
            <Route
               path={routes.moderateChanges.path}
               element={<routes.moderateChanges.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Customer') ? (
            <Route path={routes.clients.path} element={<routes.clients.component />} />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Customer') ? (
            <Route
               path={routes.editClient.path}
               element={<routes.editClient.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Customer') ? (
            <Route
               path={routes.editClientHistory.path}
               element={<routes.editClientHistory.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Customer') ? (
            <Route
               path={routes.businessClients.path}
               element={<routes.businessClients.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Customer') ? (
            <Route
               path={routes.editBusinessClients.path}
               element={<routes.editBusinessClients.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Category') ? (
            <Route path={routes.catalog.path} element={<routes.catalog.component />} />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Category') ? (
            <Route
               path={routes.catalogCategories.path}
               element={<routes.catalogCategories.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Category') ? (
            <Route
               path={routes.editCategory.path}
               element={<routes.editCategory.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Category') ? (
            <Route
               path={routes.editNewCategory.path}
               element={<routes.editNewCategory.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Category') ? (
            <Route
               path={routes.editCategoryProduct.path}
               element={<routes.editCategoryProduct.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Category') ? (
            <Route
               path={routes.editCategoryProductCharacteristics.path}
               element={<routes.editCategoryProductCharacteristics.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Category') ? (
            <Route
               path={routes.editCategoryProductVariations.path}
               element={<routes.editCategoryProductVariations.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Category') ? (
            <Route
               path={routes.editCategoryProductRelated.path}
               element={<routes.editCategoryProductRelated.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Category') ? (
            <Route
               path={routes.newCategories.path}
               element={<routes.newCategories.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Support') ? (
            <Route path={routes.support.path} element={<routes.support.component />} />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'BonusSystem') ? (
            <Route path={routes.bonuses.path} element={<routes.bonuses.component />} />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Banner') ? (
            <Route path={routes.banners.path} element={<routes.banners.component />} />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Banner') ? (
            <Route
               path={routes.bannersGrid.path}
               element={<routes.bannersGrid.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {/* <Route path={routes.delivery.path} element={<routes.delivery.component />} /> */}
         {role?.permissions.find((permission) => permission == 'Role') ? (
            <Route path={routes.roles.path} element={<routes.roles.component />} />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Role') ? (
            <Route
               path={routes.createRole.path}
               element={<routes.createRole.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Role') ? (
            <Route path={routes.editRole.path} element={<routes.editRole.component />} />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Role') ? (
            <Route
               path={routes.configureRole.path}
               element={<routes.configureRole.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         <Route path={routes.settings.path} element={<routes.settings.component />} />
         {role?.permissions.find((permission) => permission == 'SEO') ? (
            <Route path={routes.seo.path} element={<routes.seo.component />} />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'SEO') ? (
            <Route path={routes.metadata.path} element={<routes.metadata.component />} />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'SEO') ? (
            <Route path={routes.seoText.path} element={<routes.seoText.component />} />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         <Route
            path={routes.notFoundedAuth.path}
            element={<routes.notFoundedAuth.component />}
         />
         {role?.permissions.find((permission) => permission == 'Specialist') ? (
            <Route
               path={routes.manageWorkers.path}
               element={<routes.manageWorkers.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Specialist') ? (
            <Route
               path={routes.createWorker.path}
               element={<routes.createWorker.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Specialist') ? (
            <Route
               path={routes.editWorker.path}
               element={<routes.editWorker.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Specialist') ? (
            <Route
               path={routes.reviewWorker.path}
               element={<routes.reviewWorker.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Mail') ? (
            <Route
               path={routes.subscribers.path}
               element={<routes.subscribers.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Mail') ? (
            <Route
               path={routes.subscribersHistory.path}
               element={<routes.subscribersHistory.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'Mail') ? (
            <Route
               path={routes.editHistory.path}
               element={<routes.editHistory.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         {role?.permissions.find((permission) => permission == 'BonusSystem') ? (
            <Route
               path={routes.discountSystem.path}
               element={<routes.discountSystem.component />}
            />
         ) : (
            <Route
               path={routes.notFoundedPage.path}
               element={<routes.notFoundedPage.component />}
            />
         )}
         <Route
            path={routes.businessCustomerRequest.path}
            element={<routes.businessCustomerRequest.component />}
         />
         <Route path={routes.calculator.path} element={<routes.calculator.component />} />
         <Route
            path={routes.createCalculator.path}
            element={<routes.createCalculator.component />}
         />
         <Route
            path={routes.calculatorCategory.path}
            element={<routes.calculatorCategory.component />}
         />
         <Route
            path={routes.editCalculator.path}
            element={<routes.editCalculator.component />}
         />
         <Route
            path={routes.alternativeProducts.path}
            element={<routes.alternativeProducts.component />}
         />
         <Route
            path={routes.businessClientsHistoryOrders.path}
            element={<routes.businessClientsHistoryOrders.component />}
         />
         <Route path={routes.brands.path} element={<routes.brands.component />} />

         <Route path={routes.firstStage.path} element={<routes.firstStage.component />} />
      </Routes>
   )
}

export default AuthRoutes
